import React, { useEffect } from "react";
import "../Styles/Aboutus.css";
import Aos from "aos";
import { GiBirchTrees } from "react-icons/gi";
import { ImHome } from "react-icons/im";
import { MdFamilyRestroom } from "react-icons/md";
import { Container } from "react-bootstrap";
import { MdOutlineBedroomParent } from "react-icons/md";
import { BsDoorOpen } from "react-icons/bs";
import { FaKitchenSet } from "react-icons/fa6";
import { LuTent } from "react-icons/lu";
import { GiCampfire } from "react-icons/gi";
import { IoPeopleSharp } from "react-icons/io5";
import { IoIosPeople } from "react-icons/io";



const Aboutus = () => {
  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <div className="about-img">
        <img
          src="/Assets/estate9.jpg"
          alt="Contact Us"

          className="img-fluid"
        />
        <div className="about-heading">
          <h1>About Us</h1>
        </div>
      </div>

      <div className="home-part-2 p-3 mt-2" data-aos="zoom-in" data-aos-duration="2000">
        <div className="container">
          <div className="row">
            <div className=" col-md-7 p-5 about-head " data-aos="fade-down" data-aos-duration="2000">
              {/* <div className="d-flex gap-2 align-center justify-content-center">
                <h1 className="mt-4 text-center" style={{ color: "#AF6327" }} >WELCOME</h1>
                <h1 className="mt-4 text-center" > OUR HOME STAY!</h1>
                
              </div> */}
              <h1><b style={{ color: "#AF6327" }}>WELCOME</b> TO OUR HOME STAY!</h1>
              <h5 className="text-center">SOME WORDS ABOUT US</h5>
              <div className="middle-text">
                <p className="guru-krupa-text text-start">
                  Welcome to Gurukrupa Homestay, a hidden gem nestled in the heart of Coorg's lush landscapes.
                  Situated amidst sprawling coffee estates and enveloped in serene greenery,
                  our estate stay promises an unparalleled retreat into nature's embrace.
                </p>
                <p className="guru-krupa-text text-start">
                  Imagine waking up to the crisp mountain air, surrounded by verdant
                  coffee plantations and picturesque paddy fields. At Gurukrupa Homestay,
                  you can unwind by following a gentle stream that meanders through the hilly valleys, offering
                  a peaceful sanctuary for contemplation and relaxation. Embark on invigorating estate walks
                  and explore the rich biodiversity that thrives in this pristine environment.
                </p>
              </div>

            </div>
            <div className="col-md-5 left-img">
              <img
                src="/Assets/estate3.jpg"
                alt=""
                style={{ borderRadius: "8px" }}
                className="side-img img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container cont-about mb-4">
        <Container className="">
          <div className="row">
            <div className="col-md-6 about-host-img">
              <img
                src="/Assets/estate5.jpg"
                alt=""
                style={{ borderRadius: "8px" }}
                className="side-img img-fluid"
              />
            </div>
            <div className="col-md-6 ps-5 about-host-text" data-aos="zoom-in" data-aos-duration="2000">
              <div className="host-details pt-3">
              
                <p className="text-start">As the day draws to a close, gather around a crackling
                  campfire under the starlit sky. Share stories and laughter
                  with fellow travelers, forging bonds that transcend time and
                  place. Indulge your senses in the authentic flavors of Coorg
                  cuisine, lovingly prepared using local ingredients and time-honored recipes.
                  Our home-cooked meals are a culinary delight, promising a taste of tradition
                  and hospitality.</p>
                <p className="text-start">Whether you seek adventure or seek to unwind,
                  Gurukrupa Homestay offers the perfect setting to
                  rejuvenate your mind and spirit. Spend quality time
                  with loved ones, basking in the tranquility and beauty
                  that surrounds you. </p>
              </div>
            </div>
          </div>
        </Container>
      </div>


      <div className="home-part-2 mt-2" data-aos="zoom-in" data-aos-duration="2000">
        <div className="container">
          <div className="row">
            <div className=" col-md-7 p-5 about-some-words " data-aos="fade-down" data-aos-duration="2000">
              <h5 className="text-center about-some-words-head  mt-4">SOME WORDS ABOUT US</h5>
              <div className="middle-text ">
                <p className="text-start guru-krupa-text">Each moment spent here is an opportunity
                  to reconnect with yourself and your surroundings.</p>
                <p className="guru-krupa-text text-start">
                  Escape the hustle and bustle of everyday life and immerse
                  yourself in the serenity of Gurukrupa Homestay. Our cozy
                  accommodations and warm hospitality ensure a memorable stay,
                  where every detail is crafted to provide comfort and relaxation. Come and experience the essence of Coorg – where nature, culture, and tranquility converge at Gurukrupa Homestay.
                </p>

              </div>

            </div>
            <div className="col-md-5 left-img " >
              <img
                src="/Assets/room1.jpg"
                alt=""
                style={{ borderRadius: "8px" }}
                className="side-img img-fluid"
                id="side-img-id"
              />
            </div>
          </div>
        </div>
      </div>


      <div>
        <Container>
          <div className="" data-aos="zoom-in" data-aos-duration="2000">
            <div>
              <h1>Property Details</h1>
              {/* <h3><b style={{ color: "#AF6327" }}>Hosted</b> by Mr.Kushalappa .C.V.</h3> */}
            </div>
            <div className="text-start ps-3">
              <h3>Room in Homestay</h3>
              <p>Homestay featuring a living room, kitchen, tent, firecamp</p>
            </div>
            <div className=" property-details-info">
              <div className=" p-3 property-details-info-room" data-aos="fade-down" data-aos-duration="2000">
                <div><MdOutlineBedroomParent className="info-icon" /></div>
                <div className="info-para">
                  <p style={{ fontWeight: "600" }}>No.of.Rooms</p>
                  <p style={{ fontWeight: "400" }}>4 Rooms with Bed</p>
                </div>
              </div>
              <div className=" p-3 property-details-info-room" data-aos="fade-up" data-aos-duration="2000">
                <div><BsDoorOpen className="info-icon" /></div>
                <div className="info-para">
                  <p style={{ fontWeight: "600" }}>Bathroom</p>
                  <p>4 Attached & 1 Common Bathroom</p>
                </div>
              </div>
              <div className=" p-3 property-details-info-room" data-aos="fade-down" data-aos-duration="2000">
                <div><FaKitchenSet className="info-icon" /></div>
                <div className="info-para">
                  <p style={{ fontWeight: "600" }}>Kitchen</p>
                  <p>Kitchen with Dining hall </p>
                </div>
              </div>
              <div className=" p-3 property-details-info-room" data-aos="fade-up" data-aos-duration="2000">
                <div><LuTent className="info-icon" /></div>
                <div className="info-para">
                  <p style={{ fontWeight: "600" }}>Tent</p>
                  <p>2 Tents </p>
                </div>
              </div>

            </div>
            <div className=" mt-2 property-details-info1" >
              <div className=" p-3 property-details-info-room1" data-aos="fade-up" data-aos-duration="2000">
                <div><GiCampfire className="info-icon" /></div>
                <div className="info-para">
                  <p style={{ fontWeight: "600" }}>Firecamp</p>
                  <p style={{ fontWeight: "400" }}>Firecamp with Music</p>
                </div>
              </div>
              <div className=" p-3 property-details-info-room1"  data-aos="fade-down" data-aos-duration="2000">
                <div><IoIosPeople className="info-icon" /></div>
                <div className="info-para">
                  <p style={{ fontWeight: "600" }}>Guest</p>
                  <p>upto 17 Guests can accommodate </p>
                </div>
              </div>
              {/* <div className=" p-3 property-details-info-room" style={{ width: "23%" }} data-aos="fade-up" data-aos-duration="2000">
                <div><IoIosPeople className="info-icon" /></div>
                <div className="info-para">
                  <p style={{ fontWeight: "600" }}>Guest</p>
                  <p>upto 15 guests </p>
                </div>
              </div> */}


            </div>
          </div>
        </Container>
      </div>


      <Container>
        <div className="card-container mt-3">
          <div className="this-card">
            <div className="card-icon"> <p><GiBirchTrees /></p></div>
            <div className="card-content">
              <h3>Live Amidst Nature</h3>
              <p>Feel and experience nature in its fullest glory to refresh yourself.</p>
            </div>
          </div>

          <div className="this-card">
            <div className="card-icon"> <p><ImHome /></p></div>
            <div className="card-content">
              <h3>Nature Homestay</h3>
              <p>Experience the charm of Coorg heritage that has been preserved throughout the ages.</p>
            </div>
          </div>

          <div className="this-card">
            <div className="card-icon"> <p><MdFamilyRestroom /></p></div>
            <div className="card-content">
              <h3>Family & Friends</h3>
              <p>The calm and comfortable environment will make your family feel at home.</p>
            </div>
          </div>
        </div>
      </Container>


    </div>
  );
};

export default Aboutus;
