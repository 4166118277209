import React, { useEffect, useState } from "react";
import { Container, Carousel } from "react-bootstrap";
import Aos from "aos";
import "../Styles/Gallery.css";
import GalleryList from "./GalleryList";
import GalleryImgData from "./ImageData";

const Gallery = () => {

  const [Category, setCategory] = useState("")

    const [CategoryData, setCategoryData] = useState(GalleryImgData)
    useEffect(() => {
        if (Category) {
            const filterdata = GalleryImgData.filter((ele) => ele.type === Category)
            setCategoryData(filterdata)
        }
        if (Category === "all") {
            setCategoryData(GalleryImgData)
        }
    }, [Category])


  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  }, []);

  return (
    <div>



      {/* <Container fluid>
        <Carousel>
          <Carousel.Item>
            <img
              src="/Assets/remote.jpg"
              alt=""
              className="c-img img-fluid"
            />
            <Carousel.Caption>
              <h3>View Our Hotel Image Gallery</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              src="/Assets/relax.jpg"
              alt=""
              className="c-img img-fluid"
            />
            <Carousel.Caption>
              <h3>View Our Hotel Image Gallery</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              src="/Assets/umbrella-chair.jpg"
              alt=""
              className="c-img img-fluid"
            />
            <Carousel.Caption>
              <h3>View Our Hotel Image Gallery</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              src="/Assets/photorealistic.jpg"
              alt=""
              className="c-img img-fluid"
            />
            <Carousel.Caption>
              <h3>View Our Hotel Image Gallery</h3>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Container> */}

      <div className="mt-2 gallery-img" data-aos="zoom-in" data-aos-duration="2000">
      <img
          src="/Assets/estate9.jpg"
          alt="Contact Us"
          className="img-fluid"
        />
        <div className="  g-para ">
          <h1>Take a look at the beauty of our Home Stay</h1>
          <h5>"Immerse yourself in the beauty and tranquility of our homestay"</h5>
        </div>
      </div>
      {/* <GalleryList/> */}



      <div className='gallery-navbar mt-0'>
        <div className='gallery-navbar-content p-3'>
          <p className='gallery-navbar-para  p-2 '  onClick={()=>setCategory("all")}>All Collections</p>
          <p className='gallery-navbar-para  p-2 '  onClick={()=>setCategory("estate")}>Estate</p>
          <p className='gallery-navbar-para  p-2 '  onClick={()=>setCategory("kitchen")}>Kitchen</p>
          <p className='gallery-navbar-para  p-2 '  onClick={()=>setCategory("room")}>Room</p>
          <p className='gallery-navbar-para  p-2 '  onClick={()=>setCategory("tent")}>Tent</p>
        </div>
      </div>


      <Container fluid className="gallery-imgs mt-4">
        <div className="row">
        {CategoryData.map((items)=>(
            <div className="col-md-4 mb-3" key={items.id}>
            <img
              src={items.image}
              alt=""
              style={{borderRadius:"8px",height:"100%"}}
              className="g-image"
              data-aos="fade-up"
              data-aos-duration="2000"
            />
          </div>
        ))}
          {/* <div className="col-md-4 mb-3">
            <img
              src="/Assets/estate2.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-up"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/estate3.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-up"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/estate4.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-down"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/estate5.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-down"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/estate6.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-down"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/estate7.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-up"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/estate8.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-up"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/estate9.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-up"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/estate10.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-down"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/room1.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-down"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/room2.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-down"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/room3.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-down"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/room4.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-down"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/room5.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-down"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/room6.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              
              data-aos="fade-down"
              data-aos-duration="2000"
            />
          </div>

          <div className="col-md-4 mb-3">
            <img
              src="/Assets/room7.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-down"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/room8.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-down"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/room9.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-down"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/kitchen1.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-down"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/kitchen2.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-down"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/tent1.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-down"
              data-aos-duration="2000"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src="/Assets/tent2.jpg"
              alt=""
              style={{borderRadius:"8px"}}
              className="g-image"
              data-aos="fade-down"
              data-aos-duration="2000"
            />
          </div> */}

        </div>
      </Container>
    </div>
  );
};

export default Gallery;
