import './App.css';
import '../src/Styles/globalStyles.css'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Footer from './Components/Footer';
import Gallery from './Components/Gallery';
import ChatWidget from './Components/ChatWidget';
import Header from './Components/Header';
 import ContactUs from './Components/ContactUs';
 import Home from './Components/Home';
import Aboutus from './Components/Aboutus';
import LoadingPage from './Components/LoadingPage';

function App() {
  return (
    <div className="App">
     
      <Routes>
      <Route path="/" element={
        <LoadingPage/>
       } />
          <Route path="/aboutus" element={<>
         <Header/>
            <Aboutus/>
        <Footer/>
        <ChatWidget/> 
          </>} />
          <Route path="/gallery" element={<>
         <Header/>
         <Gallery/>
        <Footer/>
        <ChatWidget/> 
          </>} />
          <Route path="/contactus" element={<>
         <Header/>
         <ContactUs/>
        <Footer/>
        <ChatWidget/> 
          </>} />
      </Routes>

    
    </div>
  );
}

export default App;
