import React, { useEffect, useState } from 'react'
import WelcomePage from './WelcomePage'
import Home from './Home'
import Header from './Header'
import Footer from './Footer'
import ChatWidget from './ChatWidget'

const LoadingPage = () => {
    const [Loading, setLoading] = useState(true)

    useEffect(() => {
      const timer = setTimeout(() => {
      setLoading(false);
    }, 1500); // Set the desired delay in milliseconds (e.g., 2000ms = 2 seconds)

    return () => clearTimeout(timer);
    }, [])
    

  return (
    <div>
        
        {Loading ? <WelcomePage/> :  <>
         <Header/>
         <Home/>
        <Footer/>
        <ChatWidget/> 
          </>}

    </div>
  )
}

export default LoadingPage