import React, { useState } from "react";
import { MdOutlineMail } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import "../Styles/Footer.css";
import { Container } from "react-bootstrap";
import { IoCall } from "react-icons/io5";

const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm(formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      console.log("Form submitted successfully!");
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    } else {
      console.log("Form submission failed due to validation errors.");
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.name.trim()) {
      errors.name = "Your name is required";
    }

    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid";
    }

    return errors;
  };

  return (
    <div className="last-part mt-4 border">
      <footer>
        {/* <div className="container">
          <div className="row d-flex gap-2">
            <div className="col mt-3">
              <h4 className="qui-link text-start">Quick Links</h4>
              <p className="text-start fs-5"><a href="/aboutus" className="anchor-tag-style">About Us</a></p>
              
              <p className="text-start fs-5"><a href="/gallery" className="anchor-tag-style">Gallery</a></p>
            </div>
            <div className="col mt-3">
              <h4 className="text-start">Contact Us</h4>
              <div className="f-email d-flex gap-3">
                <p className="fs-3">
                  <MdOutlineMail />
                </p>
                <p className="fs-5 mt-2">somerandomwuw@gmail.com</p>
              </div>
              <div className="f-location d-flex gap-3">
                <p className="fs-3">
                  <IoLocationOutline />
                </p>
                <img
                  src="/Assets/loc.jpg"
                  alt=""
                  className="mt-2 loc"
                />
              </div>
              <div className="f-email d-flex gap-3">
                <p className="fs-3">
                  <FaWhatsapp />
                </p>
                <p className="fs-5 mt-2">9449685324 <br />9886494048</p>
              </div>
            </div>
            <div className="col mt-3">
              <h4 className="text-start ms-4">Feedback Form</h4>
              <form
                className="f-form"
                onSubmit={handleSubmit}
                style={{ maxWidth: "600px" }}
              >
                <div className="form-group">
                  <div className="row text-start">
                    <div className="col">
                      <label htmlFor="name" className="f-label">
                        Name*
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="f-input"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {errors.name && (
                        <span className="error-message">{errors.name}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row text-start">
                    <div className="col">
                      <label htmlFor="email" className="f-label mt-1">
                        Email*
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="f-input"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <span className="error-message">{errors.email}</span>
                      )}
                    </div>
                    <div className="col">
                      <label htmlFor="phone" className="f-label mt-1">
                        Phone
                      </label>
                      <input
                        type="tel"
                        name="phone"
                        className="f-input"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group text-start">
                  <textarea
                    className="f-text"
                    name="message"
                    placeholder="Type your message here...."
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <button type="submit" className="f-btn">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div> */}
        <Container>
          <div className="footer-main">
            <div className=" footer-sub" style={{ width: "40%" }}>
              <div className='mb-1 p-4 footer-welcome gap-2'>
                <img src="/Assets/b-logo.jpg" alt="" className='b-logo mb-2' />
                <p style={{ textAlign: "start" }}>Welcome to Gurukrupa Homestay, a hidden gem nestled in the heart of Coorg's lush landscapes. Situated amidst sprawling coffee estates and enveloped in serene greenery, our estate stay promises an unparalleled retreat into nature's embrace.</p>
              </div>
            </div>
            <div className="footer-sub" style={{ width: "35%" }}>
              <div className="p-4 footer-quick">
                <div className="mb-3 footer-quick-heading"><h3 style={{fontWeight:"bold"}} >Quick Links</h3></div>
                <div>
                <ul className="quicklinks-ul ">
                  <li>
                    <p className="text-start fs-5"><a href="/" className="anchor-tag-style">Home</a></p>
                  </li>
                  <li>
                    <p className="text-start fs-5"><a href="/aboutus" className="anchor-tag-style">About Us</a></p>
                  </li>
                  <li>
                    <p className="text-start fs-5"><a href="/gallery" className="anchor-tag-style">Gallery</a></p>
                  </li>
                  <li>
                    <p className="text-start fs-5"><a href="/contactus" className="anchor-tag-style">Contact Us</a></p>
                  </li>
                </ul>
                </div>
              </div>
            </div>
            <div className=" p-3 footer-contact " style={{ width: "25%" }}>
            <div className="footer-contact-heading" >
            <h3 style={{fontWeight:"bold"}}>Contact</h3>
            </div>
              <div className="   footer-contact-detail">
                <p ><MdOutlineMail className="footer-contact-icon" /> saigurufx@gmail.com</p>
                <p><IoCall className="footer-contact-icon" /> 9448515921 </p>
              </div>
            </div>
          </div>
        </Container>

      </footer>
    </div>
  );
};

export default Footer;
