import React from 'react'

const WelcomePage = () => {
  return (
    <div className='p-5'>
        <h1 style={{
            fontSize:"50px",
            fontFamily:"times"
        }}><b style={{color:"#AF6327"}}>Welcome</b> To GuruKrupa Homestay !</h1>
    </div>
  )
}

export default WelcomePage