import React from 'react';
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { HiOutlineHome } from 'react-icons/hi';
import { AiTwotoneMail } from 'react-icons/ai';
import { IoCall } from "react-icons/io5";

import '../Styles/Header.css';

const Header = () => {
  return (
    <div>
      <div className="head-top">
        <div className="container">
          <div className="row header-row align-items-center justify-content-between">
            <div className="col-auto d-flex gap-3">
              <div className='mb-1'>
                <a href="/"><img src="/Assets/b-logo.jpg" alt="" className='b-logo' /></a>
              </div>
            </div>
            <div className="col-auto d-flex gap-3 home-logo">
              <div className='fs-2 home-icon'><HiOutlineHome /></div>
              <div style={{ textAlign: "start" }}>
                <p className='headerpara'>Opp to Mithra Complex,Cherambane, <br /> On the Way to Bagamandala, Coorg-571201.</p>
              </div>
            </div>
            <div className="col-auto email-logo d-flex align-center gap-3">
              <div className='fs-2 mb-2 email-icon'><AiTwotoneMail /></div>
              <div style={{ textAlign: "start" }} className='pt-3 header-mail'>
                <p className='headerpara'>saigurufx@gmail.com</p>
              </div>
            </div>

            <div className='col-auto number d-flex gap-3' style={{ textAlign: "start" }}>
              <div className='fs-2 mb-2 email-icon pt-2'><IoCall /></div>
              <div className='pt-2 numberdiv'>
              <p className="num-size headerpara">9448515921</p>
              <p className="num-size headerpara">9886494048</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="headers">
        {["xl"].map((expand) => (
          <Navbar
            key={expand}
            expand={expand}
            style={{
              padding: "0px",
              background: "#271300",
              position: "sticky",
              top: "0px",
              zIndex: "999",
              backgroundColor: "#AF6327",
            }}
          >
            <Container fluid style={{ padding: "10px 20px" }}>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
                className="navbar-dark"
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton />
                <Offcanvas.Body>
                  <Nav
                    className="justify-content-evenly flex-grow-1 pe-"
                    style={{ alignItems: "center" }}
                  >
                    <div className='d-flex justify-content-between align-center w-100'>
                      <div></div>
                      <div className='d-flex justify-content-evenly align-center w-50  navbar'>
                      
                      <Nav.Link href="/" className="tail-text">
                        HOME
                      </Nav.Link>
                      <Nav.Link href="/aboutus" className="tail-text">
                        ABOUT US
                      </Nav.Link>
                      <Nav.Link href="/gallery" className="tail-text">
                        GALLERY
                      </Nav.Link>
                      <Nav.Link href="/contactus" className="tail-text">
                        CONTACT US
                      </Nav.Link></div>
                    </div>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </div>
    </div>
  );
};

export default Header;
