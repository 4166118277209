import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import '../Styles/ChatWidget.css';
import WhatsAppButton from './WhatsAppButton';

const ChatWidget = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);

  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [option1, setOption1] = useState('');
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleToggle = () => {
    setIsFormOpen(!isFormOpen);
  };

  const handleDateRangeToggle = () => {
    setShowDateRangePicker(!showDateRangePicker);
  };

  const handleDateSelect = (ranges) => {
    setFromDate(ranges.selection.startDate);
    setToDate(ranges.selection.endDate);
    setDateRange([ranges.selection]);
    setShowDateRangePicker(false); // Hide date picker after selection
  };

  const handleMessageSend = () => {
    if (inputValue.trim() === '') return;
    const newMessage = {
      text: inputValue,
      timestamp: new Date().getTime(),
      sender: 'user' // You can modify this to identify sender
    };

    setMessages([...messages, newMessage]);
    setInputValue('');
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here, e.g., send email, API call, etc.
    console.log('Form submitted with:', { name, number, email, message, option1, fromDate, toDate });
    // Reset form fields
    setName('');
    setNumber('');
    setEmail('');
    setMessage('');
    setOption1('');
    setFromDate(null);
    setToDate(null);
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }
    ]);
    // Close the form after submission
    setIsFormOpen(false);
  };

  const phoneNumber = 9448515921; // Replace with your WhatsApp phone number

  return (
    <div className="chat-widget">
      <div className="whatsapp-button-container">
        <WhatsAppButton phoneNumber={phoneNumber} />
      </div>

      {/* <button className="chat-button" onClick={handleToggle}>
        Check Available!
      </button> */}

      {isFormOpen && (
        <div className="chat-box">
          <div className="chat-header">
            <div>
              <h4>Check Availability!</h4>
            </div>
            <div>
              <button className="close-button" onClick={handleToggle}>
                X
              </button>
            </div>
          </div>

          <div className="chat-content">
            <form onSubmit={handleFormSubmit}>
              {/* Form inputs */}
              <div className="input-container">
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  required
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <textarea
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <select
                  value={option1}
                  onChange={(e) => setOption1(e.target.value)}
                  required
                >
                  <option value="">Select Option</option>
                  <option value="Option 1">Option 1</option>
                  <option value="Option 2">Option 2</option>
                </select>
                <div className="date-input-container">
                  <label>From:</label>
                  <input
                    type="date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    required
                  />
                  <label>To:</label>
                  <input
                    type="date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    required
                  />
                </div>
            
                {showDateRangePicker && (
                  <DateRangePicker
                    onChange={handleDateSelect}
                    moveRangeOnFirstSelection={false}
                    ranges={dateRange}
                    editableDateInputs={true}
                    direction="horizontal"
                  />
                )}
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="message-container">
        {/* Messages display */}
      </div>

      {/* Contact info */}
      <div className="contact-info">
        {/* WhatsAppButton is rendered above the "Check Available!" button */}
      </div>
    </div>
  );
};

export default ChatWidget;
